import React from 'react';
import { colors } from 'config/colors';

const styles = {
  container: {
    fontWeight: '300',
    fontSize: '1em',
    borderRadius: '6px',
    transition: '0.3s ease-in-out',
    padding:12
  }
};

export const PrimaryButton = ({ text, onClick, isEnabled = true, color = 'yellow', fontColor = 'white', className}) => {
  return(
    <div
      style={{
        ...styles.container,
        backgroundColor: isEnabled ? colors[color] : colors["lightGrey"],
        opacity: `${isEnabled ? '1' : '0.5'}`,
        pointerEvents: `${isEnabled ? 'auto' : 'none'}`,
        color: colors[fontColor]
      }}
      onClick={() => onClick()}
      className={`my-1 ${className}`}
    >
      <span>{text}</span>
    </div>
  );
};