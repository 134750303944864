import React from 'react';
import { colors } from 'config/colors';

const containerSize = '110';
const containerBorder = '3';
const styles = {
  container: {
    border: `${containerBorder}px solid ${colors.red}`,
    height: `${containerSize}px`,
    width: `${containerSize}px`,
    margin: 'auto',
    borderRadius: '10px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  lockerNumber: {
    fontSize: '1.8em',
  },
  topRow: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'middle',
    padding: '5px',
    paddingLeft: '8px',
    paddingRight: '5px',
    fontSize: '1.20em'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'middle'
  },
  icon: {
    height: '18px'
  },
  pulsingBorder: {
    border: `1px solid ${colors.red}`,
    height: `${containerSize}px`,
    width: `${containerSize}px`,
    position: 'absolute',
    borderRadius: '10px',
    top: `${0 - containerBorder}px`,
    left: `${0 - containerBorder}px`,
  },
  pulsingBorder1: {
    animation: 'locker-icon-pulse1 1.5s infinite ease-in-out',
  },
  pulsingBorder2: {
    animation: 'locker-icon-pulse2 1.5s infinite ease-in-out',
  }
}

export const LockerIcon = ({ lockerNumber, size, icon }) => {
  return(
    <div style={styles.container} className="p-4 mt-2 mb-2">
      <div style={{...styles.pulsingBorder, ...styles.pulsingBorder1}}></div>
      <div style={{...styles.pulsingBorder, ...styles.pulsingBorder2}}></div>
      <div style={styles.topRow}>
        <span style={styles.iconWrapper}><img src={icon} style={styles.icon}/></span>
        <span style={{lineHeight:1}}>{size}</span>
      </div>
      <span style={styles.lockerNumber}>{lockerNumber}</span>
    </div>
  );
};