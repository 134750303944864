import React from 'react';
import { colors } from 'config/colors';
import { LockerIcon } from 'components/common/LockerIcon';
import LokkerIcon from 'assets/images/icons/Lokker.svg';
import { FormattedMessage, useIntl } from 'react-intl';

const styles = {
  container: {
    backgroundColor: colors.cardBgColor,
    border: `1px solid ${colors.lightGrey}`,
    borderRadius: '6px'
  },
  text: {
    fontSize: '1em',
  }
};

export const LockerInfo = ({ text, locker }) => {

  const doorNumber = locker?.doorNumber

  return(
    <div style={styles.container} className="p-2 my-2 w-100">
      <div className="py-2 my-2"><LockerIcon lockerNumber={doorNumber} size={locker?.size} icon={LokkerIcon}/></div>
      <div className="py-2 my-2 mx-3"><span style={styles.text}><FormattedMessage id={text} values={{doorNumber}} /></span></div>
    </div>
  );
};